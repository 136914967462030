import { AfterViewInit, Component, Input } from '@angular/core';
import { Build, Project } from '@dis/models';
import { ProjectsSelectors } from '@dis/shared';
import { Store } from '@ngxs/store';

@Component({
  selector: 'label-build-info',
  templateUrl: './build-info.component.html',
  styleUrls: ['./build-info.component.css']
})
export class BuildInfoComponent implements AfterViewInit {
  @Input() build: Build

  project: Project;
  constructor(
    private store: Store
  ) { }

  ngAfterViewInit(): void {
    this.project = this.store.selectSnapshot(ProjectsSelectors.getByProjectNumber(this.build.projectNumber))[0];
  }
}
