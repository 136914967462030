<div class="app-row" style="justify-content: center;">
  <h2>{{ job?.projectNumber }}</h2>
  <div class="app-row" style="justify-content: space-between;">
    <div>
      <h3>Job# {{ job?.jobNumber }}</h3>
      <h3>{{job?.description}}</h3>
    </div>
    <h3>Container: {{ job?.shippingContainer }}</h3>
  </div>
</div>

<mat-divider></mat-divider>

<div class="app-column">
  <table>
    <tr>
      <th>Well</th>
      <th>Rig</th>
      <th>Location</th>
    </tr>
    <tr>
      <td>{{project?.well}}</td>
      <td>{{project?.rig}}</td>
      <td>{{project?.location}}</td>
    </tr>
    <tr>
      <th>Network</th>
      <th>OCSG</th>
      <th>Hole:</th>
    </tr>
    <tr>
      <td>{{project?.network}}</td>
      <td>{{project?.ocsgLease}}</td>
      <td>{{job.holeSection}}</td>
    </tr>
  </table>
</div>

<mat-divider></mat-divider>

<div class="app-column">
  <table>
    <tr>
      <th>Serial</th>
      <th></th>
      <th>Build</th>
      <th></th>
      <th>Assembly</th>
    </tr>
    @for (build of builds; track build?.buildNumber) {
    <tr>
      <td>
        {{build?.serialNumber}}
      </td>
      <td>|</td>
      <td>
        {{build?.buildNumber}}
      </td>
      <td>|</td>
      <td>
        {{build?.assemblyNumber}}
      </td>
    </tr>
    }
  </table>
</div>
