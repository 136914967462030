<div class="app-row">
  <div class="app-column" style="align-items: center;">

    <div id="print-section" class="app-row print-section border">
      <div class="app-column info">
        <img width="300px" height="125px" src="../../../assets/images/logo/drilling-logo.jpg"
          style="align-self: center;">
        @switch (data.type) {
        @case ('build') {
        <label-build-info [build]="data._data"></label-build-info>
        }
        @case ('job') {
        <label-job-info [job]="data._data"></label-job-info>
        }
        }
        <div class="print-section-footer">
          <table style="width: 100%;">
            <tr>
              <td>
                <img [src]="barcodeSrc(data._data.uid)" style="width: 150px; height: 50px;">
              </td>
              <td>
                <img [src]="qrcodeSrc(data._data)" style="width: 50px; height: 50px;">
              </td>
            </tr>
          </table>

        </div>
      </div>
    </div>

    <button mat-raised-button [useExistingCss]="true" printSectionId="print-section" ngxPrint>
      <mat-icon>print</mat-icon>
      Print
    </button>

  </div>
</div>
