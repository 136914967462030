<div class="app-row" style="justify-content: center;">
  <h1>Serial# {{build?.serialNumber}}</h1>
  <div class="app-row" style="justify-content: space-between; font-size: large;">
    <span><strong>Build</strong></span>
    <span>{{ build?.buildNumber}} | {{ build?.assemblyNumber}}</span>
  </div>
</div>

<mat-divider></mat-divider>

<div class="app-row" style="justify-content: center;">
  <h3>{{build?.projectNumber_jobNumber}}</h3>
</div>

<div class="app-column">
  <table>
    <tr>
      <th>Well</th>
      <th>Rig</th>
      <th>Location</th>
    </tr>
    <tr>
      <td>{{project?.well}}</td>
      <td>{{project?.rig}}</td>
      <td>{{project?.location}}</td>
    </tr>
  </table>
</div>
