<div [formGroup]="group">
  <mat-form-field class="chip-list">
    <mat-label>Serial numbers</mat-label>
    <mat-chip-grid #chipList aria-label="Component selection">
      @for (item of serialNumbers; track item) {
        <mat-chip-row
          (removed)="remove(item)">
          {{ item }}
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      }
    </mat-chip-grid>

    <input placeholder="Serial Number" #componentInput
      formControlName="serialNumber"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="addOnBlur"
      (matChipInputTokenEnd)="add($event)">

    <mat-autocomplete #auto="matAutocomplete"
      (optionSelected)="selected($event)">
      @for (option of filteredOptions | async; track option.serialNumber) {
        <mat-option [value]="option.serialNumber">
          {{ option.serialNumber }}
        </mat-option>
      }
    </mat-autocomplete>

  </mat-form-field>
</div>
