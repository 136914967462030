import { Component, ElementRef, ViewChild, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { debounceTime, map, startWith, tap } from 'rxjs/operators';

import { COMMA, ENTER } from '@angular/cdk/keycodes';


import { Component as JtmsComponent } from '@dis/models'
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Store } from '@ngxs/store';
import { ComponentsState, ComponentsStateActions } from '@dis/shared';

@Component({
  selector: 'app-serial-number-chips',
  templateUrl: './serial-number-chips.component.html',
  styleUrls: ['./serial-number-chips.component.css']
})
export class SerialNumberChipsComponent implements OnInit, OnDestroy {

  @Input()
  group!: UntypedFormGroup;
  @Input() toolType?: string;
  // @Input() disabled = false;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  
  components$: Observable<JtmsComponent[]>;
  filteredOptions: Observable<JtmsComponent[]>;
  serialNumbers: string[] = [];

  @ViewChild('componentInput') componentInput!: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete!: MatAutocomplete;

  formSubscription!: Subscription;

  constructor(
    private store: Store,
  ) {
    this.components$ = this.store.select(ComponentsState.items);
  }

  ngOnInit() {
    if (this.group.get('serialNumbers')?.value) {
      this.serialNumbers = this.group.get('serialNumbers')?.value.split(',');
    }

    this.formSubscription = this.group.controls['serialNumber'].valueChanges
      .pipe(
        tap(values => console.log('values', values)),
        startWith(''),
        debounceTime(500),
        tap(searchValue => {
          this.filteredOptions = this.components$.pipe(
            map(components => components.filter(component => component.serialNumber.includes(searchValue)))
          );
          // Below line is not working properly do to filter selector for the state is now being used for table state
          // this.store.dispatch(new ComponentsStateActions.FilterList(searchValue));
        })
      ).subscribe();
  }

  add(event: any): void {
    // Add numbers only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      // Add our numbers
      if ((value || '').trim()) {
        this.serialNumbers.push(value.trim());
      }

      // Reset the input value
      if (input) {
        input.value = '';
      }

      this._setControlValue(this.serialNumbers);
      this.group.controls['serialNumber'].setValue('');
    }
  }

  remove(sn: string): void {
    const index = this.serialNumbers.indexOf(sn);

    if (index >= 0) {
      this.serialNumbers.splice(index, 1);
    }

    this._setControlValue(this.serialNumbers);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.serialNumbers.push(event.option.viewValue);
    this.componentInput.nativeElement.value = '';
    this.group.controls['serialNumber'].setValue('');
  }

  private _setControlValue(value: string[]) {
    return this.group.get('serialNumbers')?.setValue(value.toString());
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
  }
}
