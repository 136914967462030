import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-can-save-dialog',
  templateUrl: './can-save-dialog.component.html',
  styleUrls: ['./can-save-dialog.component.css']
})
export class CanSaveDialogComponent {
  constructor(private dialogRef: MatDialogRef<CanSaveDialogComponent>) { }

  Ok() {
    this.dialogRef.close(true);
  }

  No() {
    this.dialogRef.close(false);
  }

}
