<div [formGroup]="form">
  <mat-form-field class="full-width">
    <input type="text" placeholder="Document Type" aria-label="placeholder" matInput formControlName="documentType"
      [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete">
      @for (option of options; track option) {
        <mat-option [value]="option">
          {{option}}
        </mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
</div>
