import { Component, Inject, Input } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Build, Job } from '@dis/models';

@Component({
  selector: 'print-label',
  templateUrl: './print-label.component.html',
  styleUrls: ['./print-label.component.css']
})
export class PrintLabelComponent {

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { type: string, _data: any  }
  ) { }

  barcodeSrc(barcode: string): string {
    return `https://barcode.tec-it.com/barcode.ashx?data=${barcode}&code=Code128`;
  }

  qrcodeSrc(data: Job | Build): string {
    const code = encodeURIComponent(`${window.location.origin}/projects/${data.projectNumber}/${data.jobNumber}`);
    return `https://qrcode.tec-it.com/API/QRCode?data=${code}&size=small`;
  }
}
