import { Component,  Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-document-type-autocomplete',
  templateUrl: './document-type-autocomplete.component.html',
  styleUrls: ['./document-type-autocomplete.component.css']
})
export class DocumentTypeAutocompleteComponent {
// tslint:disable-next-line: no-input-rename
  @Input('group')
  public form!: UntypedFormGroup;

  options: string[] = [
    'ADMIN',
    'BOM',
    'COC',
    'EDT',
    'MTR',
    'NDE',
    'PACKING_LIST',
    'PRESSURE',
    'PURCHASEORDER',
    'REDRESS',
    'SCRAP_DOC',
    'SEAL_COC',
    'THD_REPORT',
    'THD_REWORK',
    'TORQUE',
    'TRF', // Tool release form
  ];
}
