import { AfterViewInit, Component, Input } from '@angular/core';
import { Build, Job, Project } from '@dis/models';
import { BuildsSelectors, ProjectsSelectors } from '@dis/shared';
import { Store } from '@ngxs/store';

@Component({
  selector: 'label-job-info',
  templateUrl: './job-info.component.html',
  styleUrls: ['./job-info.component.css']
})
export class JobInfoComponent implements AfterViewInit {
  @Input() job: Job;

  builds: Build[];
  project: Project;

  constructor(
    private store: Store
  ) {

  }

  ngAfterViewInit(): void {
    this.builds = this.store.selectSnapshot(BuildsSelectors.getByJobNumber(this.job.jobNumber));
    this.project = this.store.selectSnapshot(ProjectsSelectors.getByProjectNumber(this.job.projectNumber))[0];
  }
}
